/**
 * Load image styled `lazy` when user scrolls to it.
 * Components must have a .lazy css style defined.
 */
function LazyLoadService() {
  const targetElements = []; // Lazy elements being observed
  const targetData = {}; // Components responsible for lazy element
  let isReady = false;
  let lazyImageObserver;

  /**
   * Get property of object at any depth
   * @param {object} obj
   * @param {string[]} args
   */
  // function getNestedProp(obj, ...args) {
  //     return args.reduce((obj, level) => obj && obj[level], obj)
  // }

  function loadLazyElement(observedElements, observer) {
    // Loop through IntersectionObserverEntry objects
    observedElements.forEach((el) => {
      // Do this if the target intersects with the root
      if (el.isIntersecting) {
        const lazyElement = el.target;
        // Call the callback on this target to set element to 'loaded'
        const data = targetData[lazyElement.id];
        const { component, callback } = data;
        callback.call(component);
        // Stop observing
        observer.unobserve(lazyElement);
      }
    });
  }

  /**
   * Create an intersection observer on DOM nodes
   * @param {object[]} lazyElements
   */
  function createObserverOn(lazyElements) {
    if (lazyImageObserver === null) return;
    // Loop through and observe each image
    lazyElements.forEach((lazyElement) => {
      if (!lazyElement) return;
      lazyImageObserver.observe(lazyElement);
    });
  }

  function getTargets() {
    if (!targetElements || targetElements.length <= 0) return undefined;
    return targetElements;
  }

  function register(component, lazyElement, callback) {
    if (lazyElement === null) return;

    // Find match
    let match = true;
    const data = targetData[lazyElement.id];
    let storedId;
    if (data) storedId = data.lazyElement.id;
    const regId = component.props.title;
    if (targetElements.length === 0 || storedId !== regId) match = false;

    if (!match) {
      // Register the component if none found
      targetData[lazyElement.id] = { component, lazyElement, callback };
      targetElements.push(lazyElement);
    } else if (isReady) {
      // Call the component's callback if it already loaded
      callback.call(component);
      return;
    }

    if (isReady) update(lazyElement);
  }

  function handleDOMContentLoaded() {
    // Do this only if IntersectionObserver is supported
    if ('IntersectionObserver' in window) {
      // Create new observer object
      lazyImageObserver = new IntersectionObserver((entries, observer) => loadLazyElement(entries, observer));
    } else {
      console.error('IntersectionObserver not supported!');
    }
    isReady = true;
    updateAll();
    document.removeEventListener('DOMContentLoaded', handleDOMContentLoaded);
  }

  /**
   * Run after the HTML document has finished loading
   * @param {objects[]} nodes
   */
  function start() {
    document.addEventListener('DOMContentLoaded', () => handleDOMContentLoaded());
  }

  function update(lazyElement) {
    if (!lazyElement) return;
    createObserverOn([lazyElement]);
  }

  function updateAll() {
    const lazyElements = getTargets();
    // Remove old observer
    if (lazyImageObserver) lazyImageObserver.disconnect();

    if (!lazyElements || lazyElements.length === 0) return;

    // Create new observers on updated list of nodes
    createObserverOn(lazyElements);
  }

  function clearAll() {
    if (lazyImageObserver) lazyImageObserver.disconnect();
    targetElements.length = 0;
    Object.keys(targetData).forEach((p) => delete targetData[p]);
  }

  return { start, update, updateAll, register, clearAll };
}

export default new LazyLoadService();
