import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { PREV, NEXT, INDEX, useCarousel } from 'components/carousel/carousel.hooks';
import { Swipeable } from 'react-swipeable';
import classNames from 'classnames';
import styles from 'components/carousel/carousel.module.scss';
import styled from 'styled-components';

// Elements used to create a Carousel

export const Wrapper = styled.div`
  width: ${(props) => props.width};
  height: inherit;
  margin: auto;
  margin-left: 0;
  overflow: hidden;
`;

export const CarouselContainer = styled.div`
  display: flex;
  postion: relative;
  flex-direction: row;
  width: ${(props) => props.width};
  height: inherit;
  transition: ${(props) => (props.sliding ? 'none' : `transform ${props.speed}ms ease`)};
  transform: ${(props) => {
    if (props.children.length <= 1) return 'translateX(0%)';
    if (!props.sliding) return 'translateX(-100%)';
    if (props.dir === INDEX) return 'translateX(-100%)';
    if (props.dir === NEXT) return 'translateX(0%)';
    if (props.dir === PREV) return 'translateX(-200%)';
    return 'translateX(-100%)';
  }};
`;

export const CarouselSlot = styled.div`
  width: ${(props) => props?.width};
  height: ${(props) => props?.height};
  order: ${(props) => props.order};
  -webkit-order: ${(props) => props.order};
`;

// Novel Carousel component
export const Carousel = (props) => {
  const shouldBlur = props.shouldBlur;
  const { state, slide, getOrder, numItems, renderNavTabs } = useCarousel({
    ...props,
    data: props.children,
  });

  /**
   * Carousel wrapped in swipeable component
   * https://www.npmjs.com/package/react-swipeable
   */
  const component = useCallback(() => {
    // Config for React Swipeable component
    const config = {
      onSwipedLeft: () => slide(NEXT),
      onSwipedRight: () => slide(PREV),
      preventDefaultTouchmoveEvent: true,
      trackMouse: false, // Use mouse to swipe?
    };

    return (
      <>
        <Swipeable {...config} className={styles.swipeArea}>
          <Wrapper width={props.width}>
            <CarouselContainer
              speed={props.speed}
              width={props.width}
              dir={state.dir}
              sliding={state.sliding}
            >
              {props.children.map((media, index) => (
                <CarouselSlot
                  key={index}
                  width={props.width}
                  height={props.height}
                  order={getOrder({ index: index, pos: state.pos, numItems })}
                  className={classNames(styles.slot, shouldBlur && styles.blurred)}
                >
                  {media}
                </CarouselSlot>
              ))}
            </CarouselContainer>
          </Wrapper>
        </Swipeable>
        {renderNavTabs()}
      </>
    );
  }, [
    getOrder,
    numItems,
    props.children,
    props.height,
    props.speed,
    props.width,
    renderNavTabs,
    shouldBlur,
    slide,
    state.dir,
    state.pos,
    state.sliding,
  ]);

  return props.children.length > 0 ? component() : null;
};

Carousel.propTypes = {
  children: PropTypes.array.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string,
  interval: PropTypes.number,
  showNav: PropTypes.bool,
  onChange: PropTypes.func,
  shouldBlur: PropTypes.bool,
  speed: PropTypes.number,
};

Carousel.defaultProps = {
  height: 'inherit',
  interval: 5000,
  showNav: false,
  onChange: () => null,
  shouldBlur: false,
  speed: 400,
};
