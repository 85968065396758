import React, { useCallback } from 'react';
import {
  Wrapper,
  CarouselContainer,
  CarouselSlot,
} from 'components/carousel/carousel.components';
import { useCarousel } from 'components/carousel/carousel.hooks';
import classNames from 'classnames';
import styles from 'components/skill-slider/skill-slider.module.scss';

export const SkillSlider = ({ Icon, title, skills, showNav = false }) => {
  const interval = 3000;
  const slides = useCallback(
    () =>
      skills.map((skill, index) => {
        const skillTitle = typeof skill === 'string' ? skill : skill.title;
        return <div key={index}>{skillTitle}</div>;
      }),
    [skills],
  );

  const { state, numItems, getOrder, renderNavTabs } = useCarousel({
    data: skills,
    showNav,
    interval,
  });
  const slideIndex = state.pos;
  const stat = skills[slideIndex]?.stat;
  const statType = skills[slideIndex]?.statType || ' YRS';

  const TextCarousel = (props) => {
    return (
      <Wrapper width={props.width}>
        <CarouselContainer
          speed={500}
          width={props.width}
          dir={state.dir}
          sliding={state.sliding}
        >
          {props.children.map((skill, index) => {
            return (
              <CarouselSlot
                key={index}
                width={props.width}
                height={props.height}
                order={getOrder({ index: index, pos: state.pos, numItems })}
                className={classNames(
                  styles.skill,
                  slideIndex !== index && styles.invisible,
                )}
              >
                {skill}
              </CarouselSlot>
            );
          })}
        </CarouselContainer>
      </Wrapper>
    );
  };

  const component = (
    <div className={styles.card}>
      {/* Stat & Icon */}
      <span className={styles.statContainer}>
        {/* {stat && (
          <div className={styles.stat}>
            {stat}
            {statType}
          </div>
        )} */}
        <Icon
          className={styles.icon}
          alt="skill icon"
          onMouseDown={(event) => event.preventDefault()}
        />
      </span>
      {/* Title Text*/}
      <span className={styles.title}>{title}</span>
      {/* Description Text (slides) */}
      <TextCarousel width="20rem" height="1.5rem" speed={500} interval={interval}>
        {slides()}
      </TextCarousel>
      {/* Nav Dots */}
      {showNav && <span className={styles.nav}>{renderNavTabs()}</span>}
    </div>
  );

  return component;
};
