import React from 'react';
import Placeholder from './placeholder.component';
import Loadable from 'react-loadable';

// Use to debug Loadable components by delaying loading
// const delayedPromise = new Promise((res, rej) => {
//   setTimeout(() => {
//     res();
//   }, 100000);
// });

/**
 * These are higher-order components used to render placeholders while the module
 * for the real component is being loaded.
 */
export const LoadablePullDown = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "component.pull-down-card" */ 'components/pull-down-card/pull-down-card.component'
    ),
  loading: Placeholder.PullDown,
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
});
export const LoadableAboutCard = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "component.about-card" */ 'components/about-card/about-card.component'
    ),
  loading: Placeholder.AboutCard,
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
});
export const LoadableBanner = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "component.banner" */ 'components/banner/banner.component'
    ),
  loading: Placeholder.Banner,
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
});
export const LoadableFooter = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "component.footer" */ 'components/footer/footer.component'
    ),
  loading: Placeholder.Footer,
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
});
export const LoadableProjectCard = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "component.project-card" */ 'components/project-card/project-card.component'
    ),
  loading: Placeholder.ProjectCard,
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
});
export const LoadableSkillSet = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "component.skill-set" */ 'components/skill-set/skill-set.component'
    ),
  loading: Placeholder.SkillSet,
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
});
export const LoadableGalleryPic = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "component.gallery-pic" */ 'components/gallery-pic/gallery-pic.component'
    ),
  loading: Placeholder.GalleryPic,
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
});
export const LoadableGridStack = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "component.grid-stack" */ 'components/grid-stack/grid-stack.component'
    ),
  loading: Placeholder.GridStack,
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
});
