/**
 * Import SVG as React Component in order to style via CSS just like fonts.
 * Edit the SVG file to include `fill="currentColor"` on any `paths` you want styled.
 */
import { ReactComponent as CodeIcon } from 'images/icons/code-solid.svg';
import { ReactComponent as DesignIcon } from 'images/icons/palette-solid.svg';
import { ReactComponent as GamesIcon } from 'images/icons/gamepad-solid.svg';
import { ReactComponent as CubesIcon } from 'images/icons/cubes-solid.svg';
import { ReactComponent as FileCodeIcon } from 'images/icons/file-code-solid.svg';
import { ReactComponent as GearsIcon } from 'images/icons/gears-solid.svg';
import { ReactComponent as PolygonIcon } from 'images/icons/draw-polygon-solid.svg';

let Data = {};

Data.banner = {
  imageUrl: 'images/lead-manticore_low.jpg',
  name: 'Sorob Raissi',
  tagline: 'Games, Web, 3d, Ai',
  links: {
    linkedIn: 'https://www.linkedin.com/in/sraissi/',
    twitter: 'https://twitter.com/SpreadShotDev',
    twitch: 'https://www.twitch.tv/dieharders',
    youtube: 'https://www.youtube.com/c/spreadshotstudios',
    github: 'https://github.com/dieharders?tab=repositories',
  },
};

Data.games = [
  {
    title: 'FlameyBird',
    subTitle: 'Game',
    images: [
      {
        low: 'images/games/FlameyBird/screen-1_low.png',
        high: 'images/games/FlameyBird/screen-1_high.jpg',
      },
      {
        low: 'images/games/FlameyBird/store_low.png',
        high: 'images/games/FlameyBird/store_high.png',
      },
      {
        low: 'images/games/FlameyBird/bird_low.png',
        high: 'images/games/FlameyBird/bird_high.png',
      },
    ],
    tools: ['GameMaker Studio', 'Aseprite'],
    info: [
      'A casual game built for Web, IOS and Android devices.',
      'Created pixel art and scripted gameplay.',
      'Integrated 3rd party analytics services (App Annie, Google Analytics).',
      'Implemented advertising APIs (Google Ads).',
    ],
    links: [['Trailer', 'https://www.youtube.com/watch?v=m4FnvH7_Ufs']],
    maxImageHeight: 230,
  },
  {
    title: 'Space Jammers',
    subTitle: 'Game',
    images: [
      {
        low: 'images/games/SpaceJammers/action_low.gif',
        high: 'images/games/SpaceJammers/action_high.gif',
      },
      {
        low: 'images/games/SpaceJammers/screen_02_low.jpg',
        high: 'images/games/SpaceJammers/screen_02_high.png',
      },
      {
        low: 'images/games/SpaceJammers/Screen_03_low.jpg',
        high: 'images/games/SpaceJammers/Screen_03_high.png',
      },
      {
        low: 'images/games/SpaceJammers/Screen_04_low.png',
        high: 'images/games/SpaceJammers/Screen_04_high.png',
      },
      {
        low: 'images/games/SpaceJammers/Screen_05_low.jpg',
        high: 'images/games/SpaceJammers/Screen_05_high.jpg',
      },
      {
        low: 'images/games/SpaceJammers/Screen_06_low.jpg',
        high: 'images/games/SpaceJammers/Screen_06_high.png',
      },
    ],
    tools: ['GameMaker Studio', 'Aseprite', 'Node.js', 'Firebase'],
    info: [
      'Pixel artist, game designer and programmer.',
      'Designed storefront marketing assets (videos, images, t-shirts).',
      'Key marketing materials aided in successful Kickstarter campaign.',
      'Lead marketing effort by giving talks, exhibiting at Pax East, Boston FIG and other conventions.',
      'Built and maintained website.',
    ],
    links: [
      ['Game Page', 'https://www.spreadshotstudios.com/SpaceJammersGame.html'],
      ['Trailer', 'https://www.youtube.com/watch?v=TAHhne6L-O4'],
    ],
  },
  {
    title: 'Mynkraf Sweeper',
    subTitle: 'Game',
    images: [
      {
        low: 'images/games/Minesweeper/mynkraf_low.png',
        high: 'images/games/Minesweeper/mynkraf_high.png',
      },
    ],
    tools: [
      'GameMaker Studio',
      'javascript',
      'Node.js',
      'JSON Web Token',
      'Express.js',
      'Firebase',
      'Aseprite',
    ],
    info: [
      'An HTML5 Mine-Sweeper game with a +100K tile Minecraft map.',
      'Gameplay Programmer and Pixel Artist.',
      'Created an authentication server to sign in players.',
      'Built a networking server to enable online real-time multiplayer.',
    ],
    links: [['Play Game', 'https://client-minesweeper.web.app']],
  },
];

Data.apps = [
  {
    title: 'Live-Stream Audience',
    subTitle: 'App',
    tools: ['Twitch API', 'javascript'],
    images: [
      {
        low: 'images/web_apps/twitchchat-1_low.png',
        high: 'images/web_apps/twitchchat-1_high.png',
      },
      {
        low: 'images/web_apps/twitchchat-2_low.png',
        high: 'images/web_apps/twitchchat-2_high.png',
      },
      {
        low: 'images/web_apps/twitchchat-3_low.jpg',
        high: 'images/web_apps/twitchchat-3_high.jpg',
      },
    ],
    info: [
      'A Twitch Chat Dashboard that displays messages based on a users presence in the channel.',
      'Chat members are represented by game characters on screen.',
      'Designed & built front-end with javascript and implemented Twitch API.',
    ],
    links: [
      ['Try App', 'https://twitch-chat-presence.firebaseapp.com/'],
      ['GitHub', 'https://github.com/dieharders/twitch-chat-presence'],
    ],
    maxImageHeight: 250,
  },
  {
    title: 'Obrew Studio',
    subTitle: 'App',
    tools: ['Python', 'Typescript', 'Next.js', 'llama-index', 'llama-cpp-python'],
    images: [
      {
        low: 'images/web_apps/obrew-doc_high.png',
        high: 'images/web_apps/obrew-doc_high.png',
      },
      {
        low: 'images/web_apps/obrew-jobs_high.png',
        high: 'images/web_apps/obrew-jobs_high.png',
      },
      {
        low: 'images/web_apps/obrew-model_high.png',
        high: 'images/web_apps/obrew-model_high.png',
      },
    ],
    info: ['An app that runs Ai locally on your device.'],
    links: [
      ['Try App', 'https://www.openbrewai.com'],
      ['GitHub', 'https://github.com/dieharders/brain-dump'],
      ['GitHub', 'https://github.com/dieharders/ai-text-server'],
    ],
    maxImageHeight: 300,
  },
  {
    title: 'Fortnite Resume',
    subTitle: 'App',
    images: [
      {
        low: 'images/web_apps/fortnite-resume-thumbnail.png',
        high: 'images/web_apps/fortnite-resume-thumbnail.png',
      },
    ],
    tools: ['Github API', 'javascript'],
    info: [
      'Fetch a GitHub profile and generate a Fortnite themed resume.',
      'Designed 2d assets and built front-end.',
    ],
    links: [['GitHub', 'https://github.com/dieharders/examples-fortnite_resume_builder']],
  },
  {
    title: 'Automated Marketing',
    subTitle: 'SaaS',
    images: [
      {
        low: 'images/web_apps/spreadfluence_icon_low.jpg',
        high: 'images/web_apps/spreadfluence_icon_high.png',
      },
    ],
    tools: [
      'Twitter API',
      'Express.js',
      'Node.js',
      'IcoMoon',
      'Bootstrap',
      'javascript',
      'Firebase',
      'JQuery',
    ],
    info: [
      'Spreadfluence is a platform for managing and building Social Media campaigns.',
      "Originally built to automate my game's marketing.",
      'Designed front-end and server-side.',
    ],
    links: [['Try App', 'https://pushhound.firebaseapp.com']],
  },
];

Data.examples = [
  {
    title: 'Hero API',
    subTitle: 'Example',
    images: [
      {
        low: 'images/examples/rest-api_low.png',
        high: 'images/examples/rest-api_high.png',
      },
    ],
    tools: ['Heroku', 'MongoDB', 'Mongoose API', 'Express.js', 'Node.js', 'javascript'],
    info: [
      'Back-End that takes requests from a client.',
      'Sends data stored on server back to client.',
      'Built Front-End to display profile data from server.',
    ],
    links: [
      ['Demo', 'https://example-restapi-client.firebaseapp.com/'],
      ['GitHub', 'https://github.com/dieharders/example-restapi-client'],
    ],
  },
  {
    title: 'CSS Playground',
    subTitle: 'Example',
    images: [
      {
        low: 'images/examples/css-grid_low.png',
        high: 'images/examples/css-grid_high.png',
      },
    ],
    tools: ['javascript', 'CSS Grid'],
    info: [
      'Displays an example implementaion of CSS Grid and its code.',
      'Interactive code samples that update CSS in real-time.',
      'Buttons apply different CSS rules to the Grid.',
    ],
    links: [
      ['Demo', 'https://example-css-grid.firebaseapp.com/'],
      ['GitHub', 'https://github.com/dieharders/example-css-grid'],
    ],
  },
  {
    title: 'Live Comments API',
    subTitle: 'Example',
    images: [
      {
        low: 'images/examples/preview-comments_low.png',
        high: 'images/examples/preview-comments_high.png',
      },
    ],
    tools: ['Heroku', 'MongoDB', 'Mongoose API', 'Express.js', 'Node.js', 'javascript'],
    info: [
      'Add/Like/Delete a comment from Mongo database.',
      'Sorts comments based on date added or likes.',
      'Calculates and display elapsed time since post.',
    ],
    links: [
      ['Demo', 'https://example-mongo-comments-client.firebaseapp.com/'],
      ['GitHub', 'https://github.com/dieharders/example-mongo-comments'],
    ],
  },
  {
    title: 'Infinite Gallery',
    subTitle: 'Example',
    images: [
      {
        low: 'images/examples/image-card-gallery_low.jpg',
        high: 'images/examples/image-card-gallery_high.jpg',
      },
    ],
    tools: ['Unsplash API', 'javascript'],
    info: [
      'This Front-End queries the Unsplash API to fetch paginated photos.',
      'Infinite scrolling! Load photos when scrolled to bottom.',
      'Resizes images to fill window.',
    ],
    links: [
      ['Demo', 'https://example-image-cards.firebaseapp.com/'],
      ['GitHub', 'https://github.com/dieharders/example-image-cards'],
    ],
  },
];

Data.cards = [
  {
    title: 'Design Skills',
    icon: DesignIcon,
    skills: [
      { title: 'Pixel Art', stat: 5 },
      { title: '3d Graphics', stat: 10 },
      { title: 'UI', stat: 5 },
      { title: 'Cinematics', stat: 2 },
      { title: 'Gameplay Scripting', stat: 5 },
    ],
  },
  {
    title: 'Things I make',
    icon: GamesIcon,
    skills: [
      'Web Apps',
      'Game Servers',
      '2d Action Games',
      '3d Action Games',
      'Single & Multiplayer Games',
    ],
  },
  {
    title: '3D Graphics Skills',
    icon: PolygonIcon,
    skills: [
      'Game Ready Assets',
      'Hard Surface Modeling',
      'UV Mapping & Texturing',
      'WebGL',
      'Motion Capture',
      'Shaders',
    ],
  },
  {
    title: 'Languages Used',
    icon: CodeIcon,
    skills: [
      { title: 'HTML5', stat: 11 },
      { title: 'CSS', stat: 11 },
      { title: 'JavaScript', stat: 5 },
      { title: 'TypeScript', stat: 4 },
      { title: 'Python', stat: 2 },
      { title: 'Node.js', stat: 2 },
      { title: 'React.js', stat: 4 },
      { title: 'Angular +2', stat: 2 },
      { title: 'GML', stat: 4 },
    ],
  },
  {
    title: 'Tech Stack',
    icon: FileCodeIcon,
    skills: [
      { title: 'Heroku', stat: 1 },
      { title: 'MongoDB', stat: 0.5 },
      { title: 'Firebase', stat: 2 },
      { title: 'Socket.io', stat: 1 },
      { title: 'RxJS', stat: 2 },
      { title: 'Material UI', stat: 2 },
      { title: 'GIT/Perforce/SVN', stat: 10 },
    ],
  },
  {
    title: 'Dev Tools',
    icon: FileCodeIcon,
    skills: [
      { title: 'Figma', stat: 4 },
      { title: 'Trello', stat: 10 },
      { title: 'Unreal Engine', stat: 8 },
      { title: 'GameMaker', stat: 4 },
      { title: 'Godot', stat: 2 },
    ],
  },
  {
    title: '3D Content',
    icon: CubesIcon,
    skills: [
      'Space Ships',
      'Realistic Environments',
      'Vehicles',
      'Stylized Characters',
      'Weapons',
      'Skyboxes',
      'Terrain',
    ],
  },
  {
    title: 'Content Creation Tools',
    icon: GearsIcon,
    skills: [
      { title: 'Maya', stat: 12 },
      { title: '3ds Max', stat: 8 },
      { title: 'Blender', stat: 5 },
      { title: 'nDo/dDo', stat: 5 },
      { title: 'Photoshop', stat: 15 },
      { title: 'zBrush', stat: 3 },
    ],
  },
];

Data.gallery = [
  {
    preview: 'images/games/SpaceJammers/Clint_Run.gif',
    link: 'images/games/SpaceJammers/Clint_Run.gif',
  },
  {
    preview: 'images/games/SpaceJammers/ClownBoss.gif',
    link: 'images/games/SpaceJammers/ClownBoss.gif',
  },
  {
    preview: 'images/games/SpaceJammers/bowser_run.gif',
    link: 'images/games/SpaceJammers/bowser_run.gif',
  },
  {
    preview: 'images/games/SpaceJammers/Brainiac.gif',
    link: 'images/games/SpaceJammers/Brainiac.gif',
  },
  {
    preview: 'images/cg/candle_Low.jpg',
    link: 'images/cg/candle_Hi.png',
  },
  {
    preview: 'images/cg/Cart_Low.jpg',
    link: 'images/cg/Cart_Hi.png',
  },
  {
    preview: 'images/cg/Brood_Low.jpg',
    link: 'images/cg/Brood_Hi.png',
  },
  {
    preview: 'images/cg/metalDetector_Low.jpg',
    link: 'images/cg/metalDetector_Hi.png',
  },
  {
    preview: 'images/cg/mixer_Low.jpg',
    link: 'images/cg/mixer_Hi.png',
  },
  {
    preview: 'images/cg/portajohn_Low.jpg',
    link: 'images/cg/portajohn_Hi.png',
  },
  {
    preview: 'images/cg/printer_Low.jpg',
    link: 'images/cg/printer_Hi.png',
  },
  {
    preview: 'images/cg/manticore_Low.jpg',
    link: 'images/cg/manticore_Hi.png',
  },
  {
    preview: 'images/cg/Columbian_Low.jpg',
    link: 'images/cg/Columbian_Hi.png',
  },
  {
    preview: 'images/cg/laptop_Low.jpg',
    link: 'images/cg/laptop_Hi.png',
  },
  {
    preview: 'images/cg/RailSword_Low.jpg',
    link: 'images/cg/RailSword_Hi.png',
  },
  {
    preview: 'images/cg/Skimmer_Low.jpg',
    link: 'images/cg/Skimmer_Hi.png',
  },
  {
    preview: 'images/cg/SodaMachine_Low.jpg',
    link: 'images/cg/SodaMachine_Hi.png',
  },
  {
    preview: 'images/cg/Truck_Low.jpg',
    link: 'images/cg/Truck_Hi.png',
  },
  {
    preview: 'images/cg/chair_Low.jpg',
    link: 'images/cg/chair_Hi.png',
  },
  {
    preview: 'images/cg/walkie_Low.jpg',
    link: 'images/cg/walkie_Hi.png',
  },
];

export default Data;
