/* eslint-disable react/display-name */
import React from 'react';
import styles from './placeholder.module.scss';

const preloader = () => (
  <div className={styles.loaderContainer}>
    <span className={styles.loader}>
      <span className={styles.loaderInner}></span>
    </span>
  </div>
);

const Placeholder = {
  PullDown: () => <div className={styles.containerPullDown}>{preloader()}</div>,
  AboutCard: () => <div className={styles.containerAboutCard}>{preloader()}</div>,
  Banner: () => (
    <div className={styles.containerBanner}>
      <h1>SOROB RAISSI</h1>
      <p>...loading...</p>
    </div>
  ),
  Footer: () => <div className={styles.containerFooter}></div>,
  ProjectCard: () => <div className={styles.containerProjectCard}>{preloader()}</div>,
  SkillSet: () => <div className={styles.containerSkillSet}></div>,
  GalleryPic: () => <div className={styles.containerGalleryPic}>{preloader()}</div>,
  GridStack: () => <div className={styles.containerGridStack}>{preloader()}</div>,
};

export default Placeholder;
