import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './App.scss';
// Data
import Data from './data.js';
// Services
import LazyLoadService from 'services/lazy-load.service';
// Pre-loaded components
import {
  // LoadablePullDown,
  // LoadableAboutCard,
  LoadableBanner,
  LoadableFooter,
  LoadableProjectCard,
  // LoadableSkillSet,
  LoadableGalleryPic,
  LoadableGridStack,
} from 'components/placeholder/loadable-components';
import { SkillSlider } from 'components/skill-slider/skill-slider.component';

const is_touch_device = () => {
  try {
    document.createEvent('TouchEvent');
    return true;
  } catch (e) {
    return false;
  }
};
window.is_touch_device = is_touch_device();

// This enables components to fire events and react to them
// @TODO Put this in a module and exec via "new EventHub"
const callbackLists = {};
const eventHub = {
  publish(eventName, data) {
    let callbackList = callbackLists[eventName];
    if (!callbackList) {
      return;
    }
    for (let i = 0; i < callbackList.length; i++) {
      callbackList[i](data);
    }
  },
  subscribe(eventName, callback) {
    if (!callbackLists[eventName]) {
      callbackLists[eventName] = [];
    }
    callbackLists[eventName].push(callback);
  },
};

class App extends Component {
  constructor(props) {
    super(props);
    window.LazyLoadService = LazyLoadService;
    this.minColumnWidth = 320;
    this.state = this.setGridColumnSize();
    this.projects = this.props.games.concat(this.props.apps, this.props.examples);
  }

  componentDidMount() {
    // Start Lazy Loading Service
    window.LazyLoadService.start();
  }

  /**
   * Return a state object to update Grid Column width
   */
  setGridColumnSize() {
    const viewWidth = window.innerWidth;

    if (
      typeof viewWidth != 'undefined' &&
      this.isMobileDevice() === true &&
      viewWidth < this.minColumnWidth
    ) {
      const newWidth = viewWidth - 50;
      return {
        // Change the column values to the minimum allowed single column width. This will make it work on mobile. Also update grid on screen rotation.
        appLayoutColumnWidth: newWidth + 36,
        appLayoutPreviewWidth: newWidth,
      };
    } else {
      return {
        appLayoutColumnWidth: this.minColumnWidth + 36, // Subtract border size and margin from appLayoutPreviewWidth
        appLayoutPreviewWidth: this.minColumnWidth,
      };
    }
  }

  isMobileDevice() {
    return (
      typeof window.orientation !== 'undefined' ||
      navigator.userAgent.indexOf('IEMobile') !== -1
    );
  }

  shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  // ***********************
  // Template
  // ***********************

  render() {
    return (
      <div className="App disableSelect">
        <section id="lead-content" className="banner-container">
          <LoadableBanner
            imageUrl={this.props.banner.imageUrl}
            name={this.props.banner.name}
            tagline={this.props.banner.tagline}
            links={this.props.banner.links}
          />
        </section>

        <div className="app-content-container">
          <div className="about-me-container">
            <div className="heading disableSelect">
              <h2>Technical Media Engineer</h2>
              <p>
                I work at the intersection of Web, Computer Graphics, and Software
                Engineering. I have built 3d environments for real-time sim engines,
                created entire video games full-stack, design and deploy websites, develop
                and maintain front-end UI for autonomous vehicles and video streaming
                platforms.
              </p>
            </div>
          </div>

          <section id="about" className="about-container">
            {this.props.cards.map((card, index) => (
              <SkillSlider
                key={index}
                Icon={card?.icon}
                title={card.title}
                skills={card.skills}
                showNav
              />
            ))}
          </section>

          <div className="heading disableSelect">
            <h2>Projects</h2>
          </div>
          <br></br>
          <section id="projects" className="projects-container">
            <LoadableGridStack eventHubRef={eventHub} {...this.state}>
              {this.shuffle(this.projects).map((project, index) => (
                <div className="projects-item" key={index}>
                  <LoadableProjectCard
                    {...this.state}
                    key={index}
                    id={index}
                    title={project.title}
                    subTitle={project.subTitle}
                    images={project.images}
                    tools={project.tools}
                    info={project.info}
                    links={project.links}
                    maxImageHeight={project.maxImageHeight}
                    eventHubRef={eventHub}
                  />
                </div>
              ))}
            </LoadableGridStack>
          </section>

          <section id="gallery">
            <div className="heading disableSelect">
              <h2>Pixel Art & 3d Assets</h2>
            </div>
            <ul className="gallery-container">
              {this.props.gallery.map((pic, index) => (
                <LoadableGalleryPic
                  key={index}
                  preview={pic.preview}
                  linkedImage={pic.link}
                />
              ))}
            </ul>
          </section>
        </div>

        <section id="footer">
          <LoadableFooter {...this.props.banner} />
        </section>
      </div>
    );
  }
}

// Set default props for data
App.defaultProps = {
  cards: Data.cards,
  banner: Data.banner,
  games: Data.games,
  apps: Data.apps,
  examples: Data.examples,
  gallery: Data.gallery,
};

App.propTypes = {
  cards: PropTypes.array.isRequired,
  banner: PropTypes.object.isRequired,
  games: PropTypes.array.isRequired,
  apps: PropTypes.array.isRequired,
  examples: PropTypes.array.isRequired,
  gallery: PropTypes.array.isRequired,
};

export default App;
